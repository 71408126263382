
.home-floating-button-left {
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 10;
  height: 40px;
  width: 40px;
  text-align: center;
}

.home-icon {
  padding-top: 5px;
}

.home-floating-button-left:hover {
  cursor: pointer;
  background: rgb(229, 229, 229);
  border-radius: 50%;
}
