.page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #F2F2F2;
}

.page-background-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-image: linear-gradient(0deg, rgba(242,242,242,1), rgba(0,242,242, 1)); */
}

.retail-login-loading-spinner .loadingIndicator svg {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-application {
  animation: infiniteLoading 2.5s ease-in-out infinite;
  width: 146px;
  height: 146px;
  box-sizing: border-box;
  border-radius: 10%;
  margin: 0 auto 8px auto;
}

.loading-title {
  animation: infiniteLoading 2.5s ease-in-out infinite;
  width: 150px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 10%;
  margin: 0 auto 8px auto;
}

.apps-grid {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 92px 24px;
}

.no-apps {
  color: white;
  text-align: center;
  font-size: 35px;
  margin-bottom: 50px;
}

a {
  color: black;
}

.disabled-app {
  cursor: auto;
}

@keyframes infiniteLoading {
  0%   {
    background-color: #AFACAF;
    opacity: 1;
  }
  50%  {
    background-color: #B9B6B9;
    opacity: .25;
  }
  50%  {
    background-color: #C3C1C3;
  }
  100% {
    background-color: #B9B6B9;
  }
}