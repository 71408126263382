.banner {
  position: absolute;
  width: 100%;
  top: 32px;
  left: 0;
  z-index: 1;
  background: #333;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  --skew: -3deg;
  transform: skewY(var(--skew));
}

.banner-container {
  width: 100%;
}

.banner-text {
  font-family: inherit;
  font-size: 20px;
  font-weight: bold;
  padding: 4px 0;
  text-align: center;
  transform: skewY(calc(-1* var(--skew))) rotate(var(--skew));
  color: rgb(250, 84, 0);
}