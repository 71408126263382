.app-card {
  position: relative;
  width: 300px;
  height: 455px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  border: 2px solid #333;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2),
              0 0px 6px rgba(0, 0, 0, 0.08);
  transition: background-color 0.2s ease-out,
              box-shadow 0.384s ease-out;
  cursor: pointer;
}

.app-card-disabled {
  background-color: rgba(224, 224, 224, 0.8);
  cursor: not-allowed;
}

.app-card:not(.app-card-disabled):hover {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3),
              0 0px 16px rgba(0, 0, 0, 0.2);
}

.app-card-body {
  position: relative;
  padding-top: 36%;
  padding-bottom: 0px;
  color: black;
  text-align: center;
}

.app-card-disabled .app-card-body {
  opacity: 0.6;
}

.app-card-body .application-title {
  padding: 0 24px;
}

.application-image {
  border-radius: 10%;
  border: 2px solid white;
}


