.seperator {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .45);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seperator::before, .seperator::after {
  content: '';
  display: block;
  height: 0.09em;
  min-width: 100%;
}

.seperator::before {
  background: linear-gradient(to left, #000 50%, #fff);
  margin-right: 4vh;
}

.seperator::after {
  background: linear-gradient(to right,  #000 50%, #fff);
  margin-left: 4vh;
}