.retail-login-container {
  position: relative;
}

.retail-login-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  overflow: auto;
  padding-bottom: 10px;
}

.retail-login-form input {
  width: 300px;
  height: 56px;
  padding: 0 0 0 16px;
  margin-bottom: 8px;
  outline: none;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  text-transform: uppercase;
}

.store-id-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.retail-login-loading-spinner {
  position: absolute;
  top: 45px;
  left: -150px;
}