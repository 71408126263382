.login-page {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-title {
  color: white;
  font-size: 35px;
  margin-bottom: 50px;
}

.login-box {
  background-color: white;
  width: 370px;
  height: 420px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}