.header-banner { 
  width: 100%;
  height: 60px;
  color: white;
  background: #343234;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
  position: fixed;
  z-index: 5;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3),
              0 4px 24px 0 rgba(0, 0, 0, 0.25);
}

.header-banner svg:hover{
  cursor: pointer;
}

.main-header {
  height: 40px;
  width: 100%;
  background-color: black;
}

.main-header svg:hover {
  cursor: pointer;
} 

.accountMenu-dropdown-content {
  display: none; 
  position: absolute;
  right: 5px;
  top: 31px;
  min-width: 200px;
  background: rgb(229, 229, 229);
  border-radius: 8px;
  color: #757575;
}

.swoosh-div {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 65px;
  top: 15px;
}

.swoosh {
  position: relative;
  top: 7px;
  left: 5px;
}

.user-div {
  position: absolute;
  right: 20px;
  top: 15px;
}

.accountMenu-dropdown-content .account-option {
 font-weight: 800;
}

.header-dropdown:hover {
  cursor: pointer;
}

.header-dropdown:hover .accountMenu-dropdown-content {
  display: block;
}

.accountMenu-dropdown-content ul li:first-child {
  color: black; 
}

.accountMenu-dropdown-content ul li {
  padding: 12px 16px;
  text-decoration: none;
}

.accountMenu-dropdown-content ul li:hover:not(:first-child) {
  color: black;
}

.circle {
  display: none;
  position: relative;
  background: rgb(229, 229, 229);
  border-radius: 50%;
  height: 2.4em;
  width: 2.5em;
  left: 31px;
  bottom: 8px;
}

.header-home-icon-div {
  position: absolute;
  top: 10px;
  left: 20px;
  height: 40px;
  width: 40px;
}

.header-home-icon-div:hover {
  cursor: pointer;
  background-color: rgb(229, 229, 229);
  border-radius: 50%;
} 

.header-home-icon {
  padding-top: 5px;
  padding-left: 7px;
}

.header-home-icon-div:hover svg path {
  fill: black;
}

.header-dropdown:hover .profile-icon {
  display: block;
  z-index: 1;
}

.header-dropdown:hover svg path {
  fill: black;
}

.header-dropdown:hover .circle {
  display: block;
}

h1:hover {
  cursor: default;
}

@media screen and (max-width: 1025px) {
  .header-dropdown:hover .profile-icon  {
    z-index: 1;
    position: absolute;
     top: 0px;
     right: 8px
  }
  .circle {
    left: 0px;
  }
}
