.true-child-app-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.true-iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home-button {
  background: white;
  position: absolute;
  height: 5%;
  width: 100%;
}

.i-frame {
  position: absolute;
  height: calc(100%);
  width: 100%;
}

.true-loading-container {
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 40px;
  line-height: .85;
  font-family: "Futura", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  src: url('../../fonts/futura.ttf') format('ttf');
}

.true-loading-container .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  flex-shrink: 0;
  flex-grow: 0;
}

.true-loading-container .true-loading {
  left: -90px;
  position: relative;
  top: 0;
  color: grey;
}

.no-access {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 50px;
  margin-top: 100px;
}